import { Stack } from '@mui/material'
import React from 'react'
import ChartCombine from 'src/components/Chart/ChartCombine'
import ChartPiece from 'src/components/Chart/ChartPiece'

function Home() {
	return (
		<Stack spacing={1} width="100%">
			<div></div>
			<ChartPiece />
			<ChartCombine />
		</Stack>
	)
}

export default Home
