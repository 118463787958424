'use client'
import { Flex, PasswordInput, PasswordInputProps } from '@mantine/core'
import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

function CustomPasswordInput({
	...props
}: PasswordInputProps & React.RefAttributes<HTMLInputElement>) {
	return (
		<PasswordInput
			visibilityToggleIcon={({ reveal }) =>
				reveal ? (
					<Flex>
						<VisibilityIcon />
					</Flex>
				) : (
					<Flex>
						<VisibilityOffIcon />
					</Flex>
				)
			}
			{...props}
		/>
	)
}

export default React.memo(CustomPasswordInput)
