import React from 'react'
import { Flex } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'

interface CustomGoBackProps {
	label: string
	onClick: () => void
}

function CustomGoBack({ onClick, label }: CustomGoBackProps) {
	return (
		<Flex
			sx={{ cursor: 'pointer', width: 'fit-content', alignItems: 'center' }}
			onClick={onClick}
		>
			<IconChevronLeft />
			<Flex
				sx={{ cursor: 'pointer', width: 'fit-content', alignItems: 'center' }}
			>
				{label}
			</Flex>
		</Flex>
	)
}

export default React.memo(CustomGoBack)
