import React from 'react'
import AuthenLayout from 'src/layouts/AuthenLayout'
import DefaultLayout from 'src/layouts/DefaultLayout'
import Home from 'src/pages/Home'
import LoginPage from 'src/pages/LoginPage'
import Message from 'src/pages/Message'
import NotFound from 'src/pages/NotFound'
import SettingsPage from 'src/pages/SettingsPage'
import SettingDetailPage from 'src/pages/SettingsPage/[id]'
import UsersPage from 'src/pages/UsersPage'
import UserDetailPage from 'src/pages/UsersPage/[id]'
import GuidersPage from 'src/pages/GuidersPage'
import GuiderDetailPage from 'src/pages/GuidersPage/[id]'
import TermsAndPolicy from 'src/pages/TermsAndPolicy'
import CategoryTrailsPage from 'src/pages/CategoryTrailsPage'
import CategoryTrailsDetailPage from 'src/pages/CategoryTrailsPage/[id]'
import TopicsPage from 'src/pages/TopicsPage'

const publicRoute = [
	{
		path: '/',
		component: Home,
		layout: DefaultLayout,
	},
	{
		path: '/message',
		component: Message,
		layout: DefaultLayout,
	},
	{
		path: '/users',
		component: UsersPage,
		layout: DefaultLayout,
	},
	{
		path: '/users/:id',
		component: UserDetailPage,
		layout: DefaultLayout,
	},
	{
		path: '/guiders',
		component: GuidersPage,
		layout: DefaultLayout,
	},
	{
		path: '/guiders/:id',
		component: GuiderDetailPage,
		layout: DefaultLayout,
	},
	{
		path: '/categorytrails',
		component: CategoryTrailsPage,
		layout: DefaultLayout,
	},
	{
		path: '/categorytrails/:id',
		component: CategoryTrailsDetailPage,
		layout: DefaultLayout,
	},
	{
		path: '/topics',
		component: TopicsPage,
		layout: DefaultLayout,
	},
	{
		path: '/settings',
		component: SettingsPage,
		layout: DefaultLayout,
	},
	{
		path: '/settings/:id',
		component: SettingDetailPage,
		layout: DefaultLayout,
	},
	//policy
	{
		path: '/terms_and_policy',
		component: TermsAndPolicy,
		layout: React.Fragment,
	},
	// login
	{
		path: '/login',
		component: LoginPage,
		layout: AuthenLayout,
	},
	{ path: '*', component: NotFound, layout: DefaultLayout },
]
const privateRoute = [] // sau nafy co be tinh tiep

export { publicRoute, privateRoute }
