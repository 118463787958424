import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
let isRefreshing = false
let refreshSubscribers = []
let refreshTokenPromise = null
axios.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('token')
		if (accessToken && !config.headers['Authorization']) {
			config.headers['Authorization'] =
				'Bearer ' + JSON.parse(accessToken ?? '')
		}
		return config
	},
	(error) => Promise.reject(error),
)
const onRefreshed = (token) => {
	refreshSubscribers.map((callback) => callback(token))
	refreshSubscribers = []
}

const addRefreshSubscriber = (callback) => {
	refreshSubscribers.push(callback)
}

const refreshToken = async () => {
	if (!isRefreshing) {
		isRefreshing = true
		try {
			const refreshToken = localStorage.getItem('refreshToken') || '""'
			const response = await axios.get('/auth/refresh', {
				headers: {
					Authorization: 'Bearer ' + JSON.parse(refreshToken),
					platform: 'CMS',
				},
			})
			if (response.data?.code === 200) {
				const newAccessToken = response.data?.results?.object?.access
				const newRefreshToken = response.data?.results?.object?.refresh
				localStorage.setItem('accessToken', JSON.stringify(newAccessToken))
				localStorage.setItem('refreshToken', JSON.stringify(newRefreshToken))
				onRefreshed(newAccessToken)
				return newAccessToken
			}
		} catch (error) {
			console.error('Unable to refresh token', error)
			if (error?.response?.data?.code === 434) {
				localStorage.clear()
			}
			throw error
		} finally {
			isRefreshing = false
			refreshTokenPromise = null // Reset the promise after refreshing
		}
	}

	if (!refreshTokenPromise) {
		refreshTokenPromise = new Promise((resolve, _reject) => {
			addRefreshSubscriber(resolve)
		})
	}

	return refreshTokenPromise
}

axios.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		const originalConfig = error.config
		if (error?.response?.data?.code === 403) {
			try {
				const newAccessToken = await refreshToken()
				if (newAccessToken) {
					originalConfig.headers['Authorization'] = 'Bearer ' + newAccessToken
					return axios(originalConfig)
				}
			} catch (refreshError) {
				console.error('Failed to refresh token', refreshError)
				if (refreshError?.response?.data?.code === 409) {
					localStorage.clear()
				}
				return Promise.reject(refreshError)
			}
		}
		if (error?.response?.data?.code === 409) {
			localStorage.clear()
		}

		return Promise.reject(error)
	},
)

export default axios
