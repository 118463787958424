import { Tooltip } from '@mantine/core'
import React from 'react'
import { textDecoration } from 'src/utils/constant'

function CustomTooltip({ label, maxWidth, ...props }) {
	return (
		<Tooltip
			label={label}
			sx={{
				maxWidth: 400,
				whiteSpace: 'normal',
				padding: 10,
				wordBreak: 'break-word',
			}}
			{...props}
		>
			<div
				style={{
					maxWidth: maxWidth,
					...textDecoration,
				}}
			>
				{label}
			</div>
		</Tooltip>
	)
}

export default React.memo(CustomTooltip)
