import { createStyles, Text } from '@mantine/core'
import { Link, RichTextEditor } from '@mantine/tiptap'
import Highlight from '@tiptap/extension-highlight'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'
interface ItemProps {
	value?: string
	form?: any
}
const useStyles = createStyles(() => ({
	error: {
		color: '#ff6b6b',
		borderColor: '#ff6b6b',
	},
}))

export function Editor({ value, form }: ItemProps) {
	const { classes } = useStyles()
	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Link,
			Superscript,
			SubScript,
			Highlight,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
		],
		content: value ? value : form?.values?.value,
		onUpdate: ({ editor }) => {
			form?.setFieldValue('value', editor.getHTML())
		},
		// onBlur: ({ editor }) => {
		// 	form?.setFieldValue('value', editor.getHTML())
		// },
	})

	useEffect(() => {
		if (JSON.stringify(value) === JSON.stringify(editor?.getHTML())) {
		} else {
			editor?.commands.setContent(value as string)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])

	return (
		<div>
			<Text fz="sm" fw={500}>
				{'Content'}
				{
					<span style={{ marginLeft: 4, color: 'red', fontWeight: 100 }}>
						*
					</span>
				}
			</Text>
			<RichTextEditor
				editor={editor}
				className={`mantine-1x2ygqk ${
					form?.errors?.value || form?.values?.value === '<p></p>'
						? classes.error
						: ''
				}`}
			>
				<RichTextEditor.Toolbar sticky stickyOffset={0}>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Underline />
						<RichTextEditor.Strikethrough />
						<RichTextEditor.ClearFormatting />
						<RichTextEditor.Highlight />
						<RichTextEditor.Code />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.H1 />
						<RichTextEditor.H2 />
						<RichTextEditor.H3 />
						<RichTextEditor.H4 />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Blockquote />
						<RichTextEditor.Hr />
						<RichTextEditor.BulletList />
						<RichTextEditor.OrderedList />
						<RichTextEditor.Subscript />
						<RichTextEditor.Superscript />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Link />
						<RichTextEditor.Unlink />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.AlignLeft />
						<RichTextEditor.AlignCenter />
						<RichTextEditor.AlignJustify />
						<RichTextEditor.AlignRight />
					</RichTextEditor.ControlsGroup>
				</RichTextEditor.Toolbar>

				<RichTextEditor.Content />
			</RichTextEditor>
			{(form?.values?.value === '<p></p>' || !value) && (
				<div style={{ color: 'red' }}>
					{!form?.values?.value && form?.errors?.value}
					{form?.values?.value === '<p></p>' && 'Content cannot be empty'}
				</div>
			)}
		</div>
	)
}
