'use client'
import React, { useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { color } from 'src/utils/constant'
import { generateRandomNumber } from 'src/utils/utils'
import { Box, Stack } from '@mui/material'
function ChartPiece() {
	const options = useMemo(() => {
		return {
			chart: {
				type: 'pie',
			},
			title: {
				text: 'Income',
			},
			tooltip: {
				formatter: function () {
					return `<tspan style="font-size: 0.8em;">${
						this.point.name
					}</tspan><br/><tspan style="color: ${this.color}; fill: ${
						this.color
					};"> ● </tspan><tspan>
						Income:</tspan> ${this.point.y.toLocaleString()} (${this.point.percentage.toFixed(
						1,
					)}%)`
				},
			},
			credits: {
				enabled: false,
			},
			plotOptions: {
				series: {
					allowPointSelect: true,
					cursor: 'pointer',
					showInLegend: true,
					size: 300,
					dataLabels: [
						{
							enabled: false,
							distance: 20,
							// formatter: function () {
							// 	console.log(this)
							// 	return `<span style="color:red">${this.point.name}</span>`
							// },
						},
						{
							enabled: true,
							distance: -40,
							format: '{point.percentage:.1f}%',
							style: {
								fontSize: '1.2em',
								textOutline: 'none',
								opacity: 0.7,
							},
							filter: {
								operator: '>',
								property: 'percentage',
								value: 10,
							},
						},
					],
				},
			},
			series: [
				{
					name: 'Income',
					colorByPoint: true,
					data: [
						{
							name: 'Hiking',
							y: generateRandomNumber(),
							color: color.ColorBrandBlue,
						},
						{
							name: 'Group',
							y: generateRandomNumber(),
							color: color.text_error,
						},
						{
							name: 'Cancel',
							y: generateRandomNumber(),
							color: color.Button_background_error,
						},

						{
							name: 'Assigned',
							y: generateRandomNumber(),
							color: color.text_brand_default,
						},
						{
							name: 'Done',
							y: generateRandomNumber(),
							color: color.text_success,
						},
					],
				},
			],
			legend: {
				align: 'left',
				verticalAlign: 'middle',
				layout: 'vertical',
			},
		}
	}, [])
	return (
		<Stack
			direction="row"
			width="100%"
			justifyContent="center"
			alignContent="center"
			sx={{ background: 'transparent' }}
		>
			<Box width="80%" sx={{ background: 'transparent' }}>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</Box>
		</Stack>
	)
}

export default React.memo(ChartPiece)
