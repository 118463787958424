import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
function AuthenLayout({ children }) {
	const navigate = useNavigate()

	useEffect(() => {
		const info = JSON.parse(localStorage.getItem('info'))
		const token = JSON.parse(localStorage.getItem('token'))
		const refresh_token = JSON.parse(localStorage.getItem('refresh_token'))

		if (info && token && refresh_token) {
			navigate('/')
		}
	}, [navigate])
	return <Box>{children}</Box>
}

export default AuthenLayout
