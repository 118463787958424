import React, { useRef } from 'react'
import { DatePickerInput, DatePickerInputProps } from '@mantine/dates'
import styles from './CustomDatePickerInput.module.scss'
import { Stack } from '@mantine/core'
interface CustomDatePickerInputProps extends DatePickerInputProps {
	[key: string]: any
}
function CustomDatePickerInput({ ...props }: CustomDatePickerInputProps) {
	const datePickerRef = useRef<HTMLButtonElement>(null)
	const handlePlaceholderClick = () => {
		if (datePickerRef.current) {
			datePickerRef.current.click()
		}
	}
	return (
		<Stack sx={{ width: '100%', position: 'relative' }}>
			<DatePickerInput ref={datePickerRef} {...props} />
			{!props?.value && (
				<span className={styles.placeholder} onClick={handlePlaceholderClick}>
					{props?.placeholder || 'DD/MM/YYYY'}
				</span>
			)}
		</Stack>
	)
}

export default React.memo(CustomDatePickerInput)
