import React, { Fragment } from 'react'
import { Grid, Stack } from '@mui/material'

function Message() {
    return (
        <Fragment>
            <Grid item xs={12}>
                <Stack>Message</Stack>
            </Grid>
        </Fragment>
    )
}

export default Message
