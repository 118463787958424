import React, { useCallback, useState } from 'react'
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import styles from './InputForm.module.scss'
function InputFormPassword({
	name,
	errors,
	register,
	label,
	placeholder = 'Enter value here',
}) {
	const [showPassword, setShowPassword] = useState(false)
	const handleTogglePasswordVisibility = useCallback(() => {
		setShowPassword((prevShowPassword) => !prevShowPassword)
	}, [])
	return (
		<Stack className={styles.wrapperInputForm} spacing={1}>
			<TextField
				label={label}
				placeholder={placeholder}
				InputLabelProps={{
					shrink: true,
				}}
				type={showPassword ? 'text' : 'password'}
				required
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={handleTogglePasswordVisibility}>
								{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					),
				}}
				{...register(name)}
			/>
			{errors[name] && <p className={styles.errors}>{errors[name].message}</p>}
		</Stack>
	)
}

export default InputFormPassword
