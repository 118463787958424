import React from 'react'
import { useParams } from 'react-router-dom'
import UserDetail from 'src/containers/UserDetail'

function UserDetailPage() {
	const params = useParams()
	const { id } = params
	return <UserDetail id={id} />
}

export default React.memo(UserDetailPage)
