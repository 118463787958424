import React, { createContext, useState, useContext, useCallback } from 'react'
import classes from './context.module.scss'

const LoadingContext = createContext({
	openLoadingContext: () => {},
	closeLoadingContext: () => {},
	loadingContext: false,
})

export const LoadingProvider = ({ children }) => {
	const [loadingContext, setLoadingContext] = useState(false)

	const openLoadingContext = useCallback(() => {
		setLoadingContext(true)
	}, [])

	const closeLoadingContext = useCallback(() => {
		setLoadingContext(false)
	}, [])

	return (
		<LoadingContext.Provider
			value={{ loadingContext, openLoadingContext, closeLoadingContext }}
		>
			{children}
			{loadingContext && (
				<div style={{ position: 'fixed', zIndex: 9999, bottom: 50, right: 0 }}>
					<span className={classes.loader}></span>
				</div>
			)}
		</LoadingContext.Provider>
	)
}

export const useLoading = () => useContext(LoadingContext)
