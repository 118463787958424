import React from 'react'
import { Select, SelectProps } from '@mantine/core'

function CustomSelect({
	sx,
	...props
}: SelectProps & React.RefAttributes<HTMLInputElement>) {
	return <Select sx={{ minWidth: 240, height: 36, ...sx }} {...props} />
}

export default React.memo(CustomSelect)
