import dayjs from 'dayjs'
import { formatDate, formatDateTime, timestampFields } from './constant'

export const generateRandomNumber = (min = 0, max = 0) => {
	return (
		Math.floor(Math.random() * ((max || 2000) - (min || 100) + 1)) +
		(min || 100)
	)
}
export const createRandomArray = (n: any) => {
	const randomArray = []
	for (let i = 0; i < n; i++) {
		const randomNumber = generateRandomNumber()
		randomArray.push(randomNumber)
	}
	return randomArray
}

export const convertParams = (params: { [key: string]: any }) => {
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)]),
	)
}

export const convertPhoneNumber = (phoneNumber) => {
	const prefixes = ['+84']
	const results = [] as any

	if (phoneNumber.startsWith('0')) {
		results.push(phoneNumber)
		prefixes.forEach((prefix) => {
			const convertedNumber = prefix + phoneNumber.slice(1)
			results.push(convertedNumber)
		})
	} else {
		results.push(phoneNumber)
	}

	return results
}
export const convertDate = (value: string, fields: string) => {
	if (value) {
		const format = timestampFields.includes(fields)
			? formatDate
			: formatDateTime
		const dateValue = isNaN(Number(value)) ? value : Number(value)
		return dayjs(dateValue).format(format)
	} else {
		return ''
	}
}
export const formatTime = (seconds: number) => {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const secs = seconds % 60

	const formattedTime = [
		hours > 0 ? `${hours}h` : '',
		minutes > 0 ? `${minutes}m` : '',
		secs > 0 ? `${secs}s` : '',
	]
		.filter(Boolean)
		.join('')

	return formattedTime || '0s' // Trả về "0s" nếu tất cả đều là 0
}
