import React from 'react'
import { Progress } from '@mantine/core'
import { color } from 'src/utils/constant'

function CustomProgress({ value = 100 }) {
	return (
		<div>
			<div style={{ opacity: 'opacity: 1; transition: opacity 200ms ease 0s' }}>
				<div className="mantine-Progress-root mantine-x3sjku" aria-busy="true">
					<Progress
						color={color.CmsPrimary}
						value={value}
						animate={true}
					></Progress>
				</div>
			</div>
		</div>
	)
}

export default React.memo(CustomProgress)
