import React from 'react'
import { useParams } from 'react-router-dom'
import SettingDetail from 'src/containers/SettingDetail'

function SettingDetailPage() {
	const params = useParams()
	const { id } = params
	return <SettingDetail id={id} />
}

export default React.memo(SettingDetailPage)
