'use client'
import React, { useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { createRandomArray } from 'src/utils/utils'
import { color } from 'src/utils/constant'
import { Box, Stack } from '@mui/material'
const categories = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]
function ChartCombine() {
	const dataBooking = createRandomArray(12)
	const dataIncome = createRandomArray(12)
	const options = useMemo(() => {
		return {
			credits: {
				enabled: false,
			},
			chart: {
				zooming: {
					type: 'xy',
				},
			},
			title: {
				text: 'Hiking',
				align: 'left',
			},
			xAxis: [
				{
					categories: [...categories],
					crosshair: true,
				},
			],
			yAxis: [
				{
					// Primary yAxis
					labels: {
						format: '{value} VNĐ',
						style: {
							color: '',
						},
					},
					title: {
						text: 'Income',
						style: {
							color: '',
						},
					},
				},
				{
					// Secondary yAxis
					title: {
						text: 'Hiking',
						style: {
							color: '',
						},
					},
					labels: {
						format: '{value}  ',
						style: {
							color: '',
						},
					},
					opposite: true,
				},
			],
			tooltip: {
				shared: true,
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: true,
					},
				},
			},
			legend: {
				align: 'center',
				// x: 100,
				verticalAlign: 'top',
				y: -7.6,
				floating: true,
				backgroundColor: 'rgba(255,255,255,0.25)',
			},
			series: [
				{
					name: 'Hiking',
					type: 'column',
					yAxis: 1,
					data: [...dataBooking],
					tooltip: {
						valueSuffix: '  ',
					},
					color: color.CmsPrimary,
				},
				{
					name: 'Income',
					type: 'spline',
					data: [...dataIncome],
					tooltip: {
						valueSuffix: ' VNĐ',
					},
				},
			],
		}
	}, [dataBooking, dataIncome])
	return (
		<Stack
			height={500}
			direction="row"
			width="100%"
			justifyContent="center"
			alignContent="center"
			sx={{ background: 'transparent' }}
		>
			<Box width="80%" sx={{ background: 'transparent' }}>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</Box>
		</Stack>
	)
}

export default React.memo(ChartCombine)
