import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { USERROUTES } from 'src/router/Api'
export const getListUser = async (params) => {
	return await axios.get(USERROUTES.name, {
		params: convertParams(params),
	})
}
export const getOneUser = async (id, params) => {
	return await axios.get(`${USERROUTES.name}/${id}`, {
		params: convertParams(params),
	})
}
export const createGuider = async (payload) => {
	return await axios.post(USERROUTES.name, payload)
}
export const deleteUser = async (id) => {
	return await axios.delete(`${USERROUTES.name}/${id}`)
}
