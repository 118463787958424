import React from 'react'
import { useParams } from 'react-router-dom'
import EditCategoryTrails from 'src/containers/CategoryTrails/EditCategoryTrails'
function CategoryTrailsDetailPage() {
	const params = useParams()
	const { id } = params
	return <EditCategoryTrails id={id} />
}

export default React.memo(CategoryTrailsDetailPage)
