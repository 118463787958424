import React from 'react'
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_PaginationState,
} from 'mantine-react-table'
import { color } from 'src/utils/constant'
import { Flex, Pagination, Space, Stack, Tooltip } from '@mantine/core'
import { IconEdit, IconInfoCircle, IconTrash } from '@tabler/icons-react'

interface TableComponentProps {
	total: number
	data: any
	handleChangePage: (value: number) => void
	handleEdit?: (row: any) => void
	handleDetail?: (row: any) => void
	handleDelete?: (row: any) => void
	pagination: MRT_PaginationState
	loading: boolean
	columns: MRT_ColumnDef[]
	enableRowActions?: boolean
	[key: string]: any
}

function TableComponent({
	total,
	data,
	handleChangePage,
	loading = false,
	pagination,
	columns,
	enableRowActions = true,
	handleEdit,
	handleDetail,
	handleDelete,
}: TableComponentProps) {
	return (
		<Stack sx={{ width: '100%' }}>
			<MantineReactTable
				mantineProgressProps={{ color: color.CmsPrimary }}
				state={{ isLoading: loading }}
				initialState={{ showColumnFilters: false, showGlobalFilter: false }}
				columns={columns}
				data={data ?? []}
				mantinePaperProps={{ shadow: '0' }}
				enableEditing={false}
				enableSorting={false}
				enableColumnActions={false}
				enableTopToolbar={false}
				enableBottomToolbar={false}
				positionActionsColumn="last"
				renderRowActions={({ row }: any) => {
					return (
						<Flex gap={8}>
							{handleDetail && (
								<Tooltip label="Detail">
									<IconInfoCircle
										style={{ cursor: 'pointer' }}
										onClick={() => handleDetail(row)}
									/>
								</Tooltip>
							)}
							{handleEdit && (
								<Tooltip label="Edit">
									<IconEdit
										style={{ cursor: 'pointer' }}
										onClick={() => handleEdit(row)}
									/>
								</Tooltip>
							)}
							{handleDelete && (
								<Tooltip label="Delete">
									<IconTrash
										style={{ cursor: 'pointer', color: 'red' }}
										onClick={() => handleDelete(row)}
									/>
								</Tooltip>
							)}
						</Flex>
					)
				}}
				rowCount={total}
				// renderTopToolbar={({}) => <></>}
				// renderBottomToolbar={({}) => <div>a</div>}
				// onPaginationChange={setPagination}
				enablePagination={false}
				// UI for Table
				mantineTableHeadCellProps={{
					sx: {
						'& .mantine-TableHeadCell-Content': {},
						background: '#FAFAFA',
					},
				}}
				// UI Cell
				mantineTableBodyCellProps={({ row }: any) => {
					return {
						sx: {
							height: 50,
							padding: '5px 0 5px 16px !important',
							whiteSpace: 'nowrap',
							boxSizing: 'border-box',
						},
					}
				}}
				// UI head
				mantineTableHeadRowProps={() => {
					return {
						sx: {
							'.mantine-TableHeadCell-Content-Wrapper': {
								color: color.ColorBrandBlue,
								fontWeight: 500,
								whiteSpace: 'nowrap',
							},
							th: {
								paddingLeft: ' 16px !important',
								paddingRight: ' 0px !important',
							},
						},
					}
				}}
				//UI Body
				// mantineTableBodyProps={() => {
				// 	return {
				// 		sx: { height: 401 },
				// 	}
				// }}
				enableRowNumbers={false}
				enableRowActions={enableRowActions}
			/>
			<Space h="sm" />
			<Pagination
				color={color.CmsPrimary}
				position="center"
				total={Math.ceil(total / pagination.pageSize) || 1}
				onChange={(value) => {
					handleChangePage(value)
				}}
				value={pagination.pageIndex}
				disabled={loading}
			/>
		</Stack>
	)
}

export default React.memo(TableComponent)
