import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import CusTomButton from 'src/components/CustomComponents/CustomButton'

function NotFound() {
	const navigate = useNavigate()
	return (
		<Stack
			width="100%"
			height="calc(100vh - 50px)"
			alignItems="center"
			justifyContent="center"
			spacing={5}
		>
			<Typography textAlign="center" variant="h2" sx={{ color: 'black' }}>
				404 NotFound
			</Typography>
			<CusTomButton variant="contained" onClick={() => navigate('/')}>
				Go Home
			</CusTomButton>
		</Stack>
	)
}

export default NotFound
