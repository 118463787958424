/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Stack } from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import { getListSetting } from 'src/apis/settingAPI'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { SettingData } from 'src/interfaces'
const pages = [
	{
		id: 0,
		value: 'Terms of Service',
	},
	{
		id: 1,
		value: 'Policy',
	},
]
function TermsAndPolicy() {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openError } = useModal()
	const [data, setData] = useState<SettingData[]>()
	const [page, setPage] = useState(0)

	const handleGetData = useCallback(async () => {
		let arr: SettingData[] = []
		openLoadingContext()
		try {
			const res = await getListSetting({
				fields: ['$all'],
				page: 1,
				limit: 10,
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				no: index + 1 + 10 * (1 - 1),
				...item,
			}))
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)

			setTimeout(() => {
				closeLoadingContext()
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		handleGetData()
	}, [])
	return (
		<Stack sx={{ padding: 10 }}>
			<Flex align="center" justify="center" gap={8} sx={{ flexWrap: 'wrap' }}>
				{pages.map((item) => (
					<CustomButton
						key={item.id}
						variant={page === item.id ? 'contained' : 'outlined'}
						onClick={() => setPage(item.id)}
						sx={{
							width: 177,
						}}
					>
						{item.value}
					</CustomButton>
				))}
			</Flex>
			<div dangerouslySetInnerHTML={{ __html: data?.[page]?.value }}></div>
		</Stack>
	)
}

export default React.memo(TermsAndPolicy)
