import { Box, Modal, Stack } from '@mui/material'
import React from 'react'
import styles from './CustomModalClose.module.scss'
import { IoMdClose } from 'react-icons/io'

function CustomModalClose({ children, open, onClose, ...props }) {
	return (
		<Modal open={open} onClose={onClose} {...props}>
			<Box className={styles.wrapperModalChangePass}>
				<Stack className={styles.container}>
					<Stack
						sx={{
							height: 25,
							width: '100%',
							position: 'relative',
						}}
					>
						<Box
							onClick={onClose}
							sx={{
								cursor: 'pointer',
								position: 'absolute',
								right: '0',
								height: '20px',
								width: '20px',
								':hover': {
									background: 'rgb(248, 249, 250)',
								},
							}}
						>
							<IoMdClose size="20px" />
						</Box>
					</Stack>
					{children}
				</Stack>
			</Box>
		</Modal>
	)
}

export default React.memo(CustomModalClose)
