import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { useLoading } from 'src/context/LoadingContext'

interface CustomButtonProps extends ButtonProps {
	children: ReactNode
}
function CustomButton({ children, ...props }: CustomButtonProps) {
	const { loadingContext } = useLoading()
	return (
		<Button disabled={loadingContext} {...props}>
			{children}
		</Button>
	)
}

export default React.memo(CustomButton)
