import React from 'react'
import { Stack, Typography } from '@mui/material'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import CustomButton from 'src/components/CustomComponents/CustomButton'

function ModalConfirm({ open, handleClose, label, handleConfirm }) {
	return (
		<CustomModalClose
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Stack alignItems="center" spacing={4} sx={{ margin: '20px 0' }}>
				{/* <GiConfirmed size={80} color="#124984" /> */}
				<Typography sx={{ textAlign: 'center' }}>{label}</Typography>
				<Stack direction="row" spacing={5}>
					<CustomButton
						variant="outlined"
						sx={{
							width: 100,
							background: 'red',
							color: 'white',
							border: '1px solid red',
							':hover': {
								color: 'red',
								border: '1px solid red',
							},
						}}
						onClick={handleClose}
					>
						Cancel
					</CustomButton>
					<CustomButton
						variant="outlined"
						sx={{
							width: 100,
							background: '#124984',
							color: 'white',
							':hover': {
								color: '#124984',
							},
						}}
						onClick={handleConfirm}
					>
						Oke
					</CustomButton>
				</Stack>
			</Stack>
		</CustomModalClose>
	)
}

export default React.memo(ModalConfirm)
