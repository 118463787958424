import React from 'react'
import { Stack } from '@mui/material'
import { dateFields } from 'src/utils/constant'
import styles from './CustomFieldItem.module.scss'
import { convertDate } from 'src/utils/utils'
interface CustomeFieldItemProps {
	field: { value: any; label: any; width?: any }
	value: any
	[key: string]: any
}

function CustomFieldItem({ field, value }: CustomeFieldItemProps) {
	return (
		<Stack
			key={field.value}
			className={field?.width ? styles.containnerFull : styles.containner}
			spacing={1}
		>
			<div style={{ fontWeight: 700 }}>{field.label}:</div>
			<div style={{ wordBreak: 'break-word' }}>
				{dateFields.includes(field.value)
					? convertDate(value, field.value)
					: value}
			</div>
		</Stack>
	)
}

export default React.memo(CustomFieldItem)
